import React, { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import Layer from 'components/template-designer/types/layer.type';
import Translation from 'components/data/Translation';
import { Attributes } from 'components/template-designer/types/attribute.type';
import { View } from 'components/template-designer/types/template.type';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import User from 'components/data/User';
import Typography from 'components/ui-components-v2/Typography';
import { attributeInputs } from '../../dynamic-layers/components/dynamic-layer-edit/config/attribute-inputs';
import { Selected } from '..';
import { InputAttribute } from '../../dynamic-layers/types/attribute.type';
import { DynamicLayerInputDialogAttributesList } from './dynamic-layer-input-dialog-attributes-list';
import '../styles/dynamic-layer-input-dialog-attributes.scss';

interface Props {
    className?: string;
    title?: string;
    selected: Selected;
    alreadySelected: Selected;
    selectedLayer: Layer;
    showLinkedInputs?: boolean;
    leftSideWidthDifference: number;
    onSelectionChange: (layerKey: Layer['key'], attribute: Attributes) => void;
}

const DynamicLayerInputDialogAttributes = ({
    className,
    title,
    selected,
    alreadySelected,
    selectedLayer,
    showLinkedInputs,
    leftSideWidthDifference,
    onSelectionChange
}: Props) => {
    const attributesList = useRef<HTMLDivElement>(null);

    /**
     * Scroll to top when selected layer changes.
     */
    useEffect(() => {
        if (!attributesList.current) return;
        attributesList.current.scrollTop = 0;
    }, [selectedLayer]);

    /**
     * Get relevant attributes.
     */
    const relevantAttributes = useMemo(() => {
        if (getTemplateData<View['frameType']>('view.frameType') === 'base') return [];
        return Object.values(attributeInputs?.[selectedLayer.type]?.relevant ?? []).map((value) => value);
    }, [selectedLayer.type]);

    /**
     * Get all other attributes.
     * Filter out relevant attributes.
     */
    const otherProperties = useMemo(() => {
        let attributes = attributeInputs[selectedLayer.type].all;

        if (User.get('type') === 'superadmin') {
            const superAdminAttributes = attributeInputs[selectedLayer.type].superAdmin;
            if (superAdminAttributes) attributes = { ...attributes, ...superAdminAttributes };
        }

        if (relevantAttributes.length) {
            const attributesKeys = Object.keys(attributes);

            // Filter out relevant attributes.
            return Object.values(
                attributesKeys.reduce(
                    (all, item) => {
                        if (!relevantAttributes.find((input) => input.attribute === item)) all[item] = attributes[item];
                        return all;
                    },
                    {} as Record<string, InputAttribute<unknown>>
                )
            ).map((value) => value);
        }

        return Object.values(attributes).map((value) => value);
    }, [selectedLayer.type, relevantAttributes]);

    return (
        <div
            className={classNames('template-designer__dynamic-layer-input-dialog-attributes', className)}
            ref={attributesList}
            style={{ width: 400 + leftSideWidthDifference }}>
            {title && (
                <Typography variant="h4" className="template-designer__dynamic-layer-input-dialog-attributes__title">
                    {title}
                </Typography>
            )}

            {relevantAttributes.length > 0 && (
                <DynamicLayerInputDialogAttributesList
                    title={Translation.get('dynamicLayers.dynamicLayerInput.mostRelevant', 'template-designer')}
                    list={relevantAttributes}
                    selected={selected}
                    alreadySelected={alreadySelected}
                    onSelectionChange={onSelectionChange}
                    selectedLayer={selectedLayer}
                    showLinkedInputs={showLinkedInputs}
                />
            )}

            {otherProperties.length > 0 && (
                <DynamicLayerInputDialogAttributesList
                    title={
                        relevantAttributes.length > 0
                            ? Translation.get('dynamicLayers.dynamicLayerInput.otherProperties', 'template-designer')
                            : Translation.get('dynamicLayers.dynamicLayerInput.allProperties', 'template-designer')
                    }
                    list={otherProperties}
                    selected={selected}
                    alreadySelected={alreadySelected}
                    onSelectionChange={onSelectionChange}
                    selectedLayer={selectedLayer}
                    showLinkedInputs={showLinkedInputs}
                />
            )}
        </div>
    );
};

export { DynamicLayerInputDialogAttributes };
