import React, { useMemo } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Layer from 'components/template-designer/types/layer.type';
import { View } from 'components/template-designer/types/template.type';
import Translation from 'components/data/Translation';
import { NO_PREVIEW_LAYER } from 'components/template-designer/constants';
import FormatHelpers from 'components/template-designer/helpers/format.helpers';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Typography from 'components/ui-components-v2/Typography';
import { PreviewLayer } from '../../canvas/components/preview-layer';
import PreviewFormat from '../../canvas/components/preview-format';
import '../styles/dynamic-layer-input-dialog-preview-layer.scss';

interface Props {
    layer: Layer;
    frameType: View['frameType'];
}

const WIDTH = 250;
const HEIGHT = 250;

const DynamicLayerInputDialogPreviewLayer = ({ layer, frameType }: Props) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const formatSelected = layer.type === 'format';

    /**
     * Get the most square format.
     */
    const squareFormat = useMemo(() => {
        if (!formatSelected) return;
        return FormatHelpers.getMostSquareFormat();
    }, [formatSelected]);

    return (
        <div className="template-designer__dynamic-layer-input-dialog-preview-layer">
            {!formatSelected && !NO_PREVIEW_LAYER.includes(layer.type) && (
                <Typography variant="body2" className="template-designer__dynamic-layer-input-dialog-preview-layer__title">
                    {Translation.get('dynamicLayers.dynamicLayerInput.previewLayer', 'template-designer')}
                    <Tooltip title={Translation.get('dynamicLayers.dynamicLayerInput.previewLayerTooltip', 'template-designer')}>
                        <Icon className="template-designer__dynamic-layer-input-dialog-preview-layer__title-tooltip">info</Icon>
                    </Tooltip>
                </Typography>
            )}

            {formatSelected && squareFormat && (
                <Typography variant="body2" className="template-designer__dynamic-layer-input-dialog-preview-layer__title">
                    {Translation.get('dynamicLayers.dynamicLayerInput.previewFormat', 'template-designer')}
                </Typography>
            )}

            {!formatSelected && NO_PREVIEW_LAYER.includes(layer.type) && (
                <Typography variant="body2" className="template-designer__dynamic-layer-input-dialog-preview-layer__label">
                    {Translation.get('dynamicLayers.dynamicLayerInput.noPreviewLayer', 'template-designer')}
                </Typography>
            )}

            {!formatSelected && !NO_PREVIEW_LAYER.includes(layer.type) && (
                <PreviewLayer
                    key={layer.key}
                    className="template-designer__dynamic-layer-input-dialog-preview-layer__preview-layer"
                    frameType={frameType}
                    layer={layer}
                    size={{ width: WIDTH, height: HEIGHT }}
                    includeBackground
                />
            )}

            {formatSelected && squareFormat && (
                <PreviewFormat key={layer.key} format={squareFormat} frameType={frameType} size={{ width: WIDTH, height: HEIGHT }} showLayers={false} />
            )}
        </div>
    );
};

export { DynamicLayerInputDialogPreviewLayer };
