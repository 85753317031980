import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import Translation from 'components/data/Translation';
import Nestable from 'components/ui-base/Nestable';
import Layer from 'components/template-designer/types/layer.type';
import User from 'components/data/User';
import { DYNAMIC_LAYER_DIALOG_MAX_WIDTH, TREE_INDENT_SIZE } from 'components/template-designer/constants';
import SearchField from 'components/ui-components/SearchField';
import { Selected } from '..';
import ListItem from '../../ui-components/list-item';
import LayerIcon from '../../ui-components/layer-icon';
import CollapseIcon from '../../ui-components/collapse-icon';
import { VerticalResizeBar } from '../../ui-components/vertical-resize-bar';
import '../styles/dynamic-layer-input-dialog-layers.scss';

interface Props {
    layers: Layer[];
    selected: Selected;
    selectedLayer: Layer | null;
    width: number;
    setSelectedLayer: (layer: Layer) => void;
    setDynamicLayerInputLayerWidth: (width: number) => void;
}

const DynamicLayerInputDialogLayers = ({ layers, selected, selectedLayer, width, setSelectedLayer, setDynamicLayerInputLayerWidth }: Props) => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    /**
     * Filter layers based on search term.
     */
    const currentLayers = useMemo(() => {
        const newLayers: Layer[] = [];

        const findLayers = (layers: Layer[]) => {
            layers.forEach((layer) => {
                if (layer.children) {
                    findLayers(layer.children);
                }

                if (layer.title.toLowerCase().includes(searchTerm.toLowerCase())) {
                    newLayers.push(layer);
                }
            });
        };

        findLayers(layers);

        return newLayers;
    }, [layers, searchTerm]);

    return (
        <div className="template-designer__dynamic-layer-input-dialog-layers" style={{ width: width }}>
            <SearchField
                className="template-designer__dynamic-layer-input-dialog-layers__search"
                size="small"
                searchPlaceholder={`${Translation.get('labels.search', 'common')} ${Translation.get('general.labels.layer_other', 'template-designer').toLowerCase()}`}
                onChange={(_, searchTerm) => setSearchTerm(searchTerm)}
                wait={false}
            />

            <div className="template-designer__dynamic-layer-input-dialog-layers__list">
                {searchTerm &&
                    currentLayers.map((layer) => (
                        <ListItem
                            key={layer.key}
                            title={layer.title}
                            id={layer.key}
                            onClick={() => setSelectedLayer(layer)}
                            isActive={selectedLayer?.key === layer.key}
                            icon={<LayerIcon layer={layer} isActive={selectedLayer?.key === layer.key} />}
                        />
                    ))}

                {!searchTerm && (
                    <Nestable
                        items={layers}
                        draggable={false}
                        activeItem={null}
                        renderItem={({ item, collapseIcon }: { item: Layer; collapseIcon?: React.ReactElement }) => {
                            const totalSelected = selected[item.key]?.length || 0;
                            const isActive = selectedLayer?.key === item.key;

                            return (
                                <ListItem
                                    className="template-designer__dynamic-layer-input-dialog-layers__list-item"
                                    collapseIcon={collapseIcon}
                                    title={item.title}
                                    id={item.key}
                                    onClick={() => setSelectedLayer(item)}
                                    isActive={isActive}
                                    icon={<LayerIcon layer={item} isActive={selectedLayer?.key === item.key} />}
                                    actions={
                                        totalSelected ? (
                                            <div
                                                className={classNames('template-designer__dynamic-layer-input-dialog-layers__selected', {
                                                    'template-designer__dynamic-layer-input-dialog-layers__selected--active': isActive
                                                })}>
                                                {totalSelected}
                                            </div>
                                        ) : null
                                    }
                                    tooltip={User.get('type') === 'superadmin' ? item.key : ''}
                                />
                            );
                        }}
                        renderCollapseIcon={(props) => <CollapseIcon {...props} />}
                        collapsed
                        indentSize={TREE_INDENT_SIZE}
                    />
                )}
            </div>
            <VerticalResizeBar
                width={width}
                maxWidth={DYNAMIC_LAYER_DIALOG_MAX_WIDTH}
                onResize={(newWidth) => {
                    setDynamicLayerInputLayerWidth(newWidth);
                }}
            />
        </div>
    );
};

export { DynamicLayerInputDialogLayers };
